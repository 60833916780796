import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import PageHero from "../components/pageHero";
import Gallery from "../components/gallery/gallery";
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
class GalleryIndex extends React.Component {
  
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const gallery = get(this, "props.data.allContentfulBlogPost.edges")[0].node.photos
    const heroImage = get(this, "props.data.allContentfulBlogPost.edges")[0].node

    const container = {
      width: typeof window !== 'undefined' && window.innerWidth,
      // width: 1300,
      height:1000
    };

    const options = {
      galleryLayout: -1,
      gridStyle: 1,
      groupsPerStrip: 3,
      imageHoverAnimation: 'ZOOM_IN',
      imagePlacementAnimation: 'SLIDE',
      hoveringBehaviour: 'NEVER_SHOW',
    };

    const scrollingElement = typeof window !== 'undefined' && window

    const newImageData = [];
        gallery.forEach((image) => {
            let imageItem = [];
            imageItem = {
                itemId: image.id,
                mediaUrl: image.sizes.src,
                metaData: {
                    type: 'image',
                    height: image.resolutions.height,
                    width: image.resolutions.width,
                    title: image.title,
                    description: image.description,
                    focalPoint: [0, 0],
                    link: {
                        url: 'http://example.com',
                        target: '_blank'
                    }
                }
            }
            newImageData.push(imageItem);
        })
    return (
      <Layout>
        <Helmet title={`Gallery | ${siteTitle}`} />
        <PageHero hero={heroImage} />
        <div className="container-fluid">
          <div className="row hide_small">
            <div className="col-md-12 mb-2">
            <ProGallery
              items={newImageData}
              options={options}
              container={container}
              scrollingElement={scrollingElement}
             />
            
            </div>
          </div>

          <div className="row hide_large">
            <div className="col-md-12">
            <Gallery
              data={heroImage.photos}
            />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default GalleryIndex

export const pageQuery = graphql`
  query galleryQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { contentful_id: { eq: "1ExCukUAxoXluYCxMynrPO" } }
      sort: { fields: [sort], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            sizes(maxWidth: 1024, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          photos {
            id
            title
            sizes(maxWidth: 1024, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
            resolutions {
              height
              width
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
